import { graphql, useStaticQuery } from 'gatsby';
import groupBy from 'lodash.groupby';
import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import ContentContainer from '../components/common/ContentContainer';
import Layout from '../components/Layout';
import SearchInput from '../components/SearchInput';
import { Staff } from './../components/Staff';
import StaffBio from './../components/StaffBio';
import SEO from '../components/Seo';

interface StaffCardProps {
  staffPerson: Staff;
  staffFallbackImage: any;
  setSelectedStaff: any;
}
const StaffCard: React.FC<StaffCardProps> = props => {
  return (
    <div className="h-full mb-8 bg-white rounded-lg shadow-md">
      <div className="p-6">
        <h3 className="text-2xl text-primary">{props.staffPerson.firstName}</h3>
        <h3 className="text-2xl text-gray-500">{props.staffPerson.lastName}</h3>
        <p className="h-16 mt-4 text-primary sans-serif">
          {props.staffPerson.title}
        </p>
      </div>
      <div className="h-64 overflow-hidden bg-gray-700">
        <img
          className="object-cover"
          src={
            props.staffPerson.photo?.file?.url ??
            props.staffFallbackImage.childImageSharp.sizes.src
          }
          alt={`${props.staffPerson.firstName} ${props.staffPerson.lastName} photo`}
        />
      </div>
      <div className="px-6 mt-6">
        <button
          onClick={() => props.setSelectedStaff(props.staffPerson)}
          className="text-primary hover:underline"
        >
          Read Bio &rarr;
        </button>
      </div>
    </div>
  );
};

interface StaffSectionProps {
  staff: Staff[];
  staffFallbackImage: any;
  setSelectedStaff: any;
}

const groupStaffByDepartment = (staff: Staff[]) => {
  const grouped = groupBy(staff, 'category');

  return {
    'Mossflower Staff': grouped['Senior Leaders'] || [],
    Consultants: grouped['Staff Developers'] || []
  };
};

const StaffSection: React.FC<StaffSectionProps> = props => {
  const [visibleStaff, setVisibleStaff] = useState(props.staff);
  const [searchTerm, setSearchTerm] = useState(null);
  const debounced = useDebouncedCallback(staff => setVisibleStaff(staff), 300);
  return (
    <section className="w-5/6 m-auto">
      <div className="mb-6">
        <div className="flex flex-col items-center justify-center text-center">
          <h2 className="mb-4 text-3xl font-bold text-primary">Our People</h2>
          <div className="self-center w-3/4 mt-2">
            <p className="self-center w-full m-auto text-xl text-gray-500 smw-2/3">
              Our staff have been classroom teachers, coaches, administrators,
              district leaders, college instructors, researchers, staff
              developers at RWP, and writers. Many hold advanced degrees and
              have done specialized coursework in Special Education, Orton
              Gillingham, LETRS, and the like.
              <br />
              <br />
              The strength of our organization lies not only in the
              extraordinary educators who comprise our team, but also in our
              learning community. Every Thursday for decades, Calkins and the
              members of the Mossflower team have collaborated in think tanks
              and inquiry teams, democratizing our knowledge. These study groups
              are nurtured by work in schools which allows us to develop and
              field-test best practices alongside some of the world’s finest
              educators.
            </p>
          </div>
          {/* <h2 className="mt-4 mb-4 text-3xl font-bold text-primary">Staff</h2> */}
          <div className="mt-6 sm:w-full lg:w-1/3">
            <SearchInput
              onChange={(e: any) => {
                if (!e.target.value) {
                  setSearchTerm(null);
                  return debounced.callback(props.staff);
                }
                setSearchTerm(e.target.value);
                const term = new RegExp(e.target.value, 'gi');
                debounced.callback(
                  props.staff.filter((s: Staff) => {
                    return (
                      `${s.firstName} ${s.lastName}`.match(term) ||
                      s.firstName.match(term) ||
                      s.lastName.match(term) ||
                      s.title.match(term)
                    );
                  })
                );
              }}
              placeholder="Search staff"
            />
          </div>
        </div>
        <div className="mt-4">
          {searchTerm && (
            <p className="italic text-gray-500">
              Showing results for filter "{searchTerm}"
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-col px-12 lg:px-0">
        <div className="grid gap-1 m-auto grid-col-1 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:gap-8">
          {visibleStaff.map((s: Staff, i: number) => (
            <div key={i} className="mb-4 lg:mb-0">
              <StaffCard
                staffPerson={s}
                staffFallbackImage={props.staffFallbackImage}
                setSelectedStaff={props.setSelectedStaff}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const OurPeople: React.FC = props => {
  const [selectedStaff, setSelectedStaff] = useState();
  const data = useStaticQuery(graphql`
    {
      staffFallbackImage: file(
        relativePath: { eq: "images/staff-fallback.png" }
      ) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      staff: allContentfulStaff(
        sort: { fields: sortOrder, order: ASC }
        filter: { node_locale: { eq: "en-US" } }
      ) {
        edges {
          node {
            id
            firstName
            lastName
            title
            category
            sortOrder
            bio {
              bio
            }
            photo {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);
  const staff = data.staff.edges.map((e: any) => e.node);
  return (
    <Layout>
      <SEO title="Our People" />
      <ContentContainer>
        <StaffSection
          staff={staff}
          staffFallbackImage={data.staffFallbackImage}
          setSelectedStaff={setSelectedStaff}
        />
      </ContentContainer>
      {selectedStaff && (
        <div className="">
          <StaffBio
            profile={selectedStaff}
            staffFallbackImage={
              data.staffFallbackImage.childImageSharp.sizes.src
            }
            onClose={() => setSelectedStaff(null)}
          />
        </div>
      )}
    </Layout>
  );
};

export default OurPeople;
